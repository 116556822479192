import {Link} from '@remix-run/react';
import {Button} from '#/app/components/Button.jsx';
import {Container} from '#/app/components/Container.jsx';
import {FAQ} from '#/app/components/FAQ.jsx';
import {Feature} from '#/app/components/Feature.jsx';
import {Image} from '#/app/components/Image.jsx';
import {TalkToUsButton} from '#/app/components/TalkToUsButton.jsx';
import {TrustedBy} from '#/app/components/TrustedBy.jsx';
import {FEATURES, GeneralFAQ} from '#/app/content.js';

export default function Index() {
  return (
    <>
      <div className="overflow-hidden">
        <Container className="relative flex flex-col pt-20 text-gray-900 md:flex-row">
          <div className="flex w-full items-start pb-8 pt-0 md:w-7/12 md:py-0 lg:mt-0 lg:w-5/12 2xl:items-center">
            <div className="space-y-10">
              <h1 className="text-5xl md:text-6xl lg:text-7xl">
                <em>AI-powered</em>
                <br />
                {' '}
                route optimization software
              </h1>
              <p className="ml-0.5 text-xl leading-relaxed text-gray-600 sm:text-2xl">
                Reduce delivery costs, optimize routes, and empower your drivers. All while providing a stellar delivery experience to
                customers.
              </p>
              <div className="flex space-x-4">
                <Button
                  appearance="primary"
                  className="_cta"
                  color="sky"
                  href="/register"
                  size="lg"
                >
                  Try Ufleet for Free
                </Button>
                <TalkToUsButton color="sky" />
              </div>
              <div className="pl-1">
                <Link className="text-xl text-gray-500 hover:text-sky-500" to="/demo">
                  See how
                  {' '}
                  <span className="font-medium text-sky-500 underline">Ufleet</span>
                  {' '}
                  works ➔
                </Link>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-1 items-center justify-center pl-0 pt-4 md:block md:w-5/12 md:pl-8 md:pt-0 lg:w-7/12">
            <div className="relative w-full md:hidden">
              <Image
                alt="Deliver more with Ufleet"
                priority
                src="/ufleet-ai-powered-route-optimization.png"
              />
            </div>
            <div className="hidden w-full md:block">
              <Image
                alt="Deliver more with Ufleet"
                priority
                src="/ufleet-ai-powered-route-optimization.png"
                style={{maxWidth: 'none', width: 1141}}
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-gray-100x mt-8 flex h-[400px] w-full items-center justify-center">
        <TrustedBy />
      </div>
      <Container className="relative pt-6 text-gray-900">
        <div className="space-y-24">
          {FEATURES.map((feature) => (
            <Feature feature={feature} key={feature.name} />
          ))}
        </div>

        <div className="my-32">
          <FAQ content={GeneralFAQ} />
        </div>
      </Container>
    </>
  );
}
